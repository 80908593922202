/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('./css/global.scss');
require('./css/aaf.scss');
require('./css/fellow.scss');
require('./css/mainframe.scss');
require('./css/widget.scss');
require('./css/reports.scss');

// noinspection JSUndefinedPropertyAssignment
global.$ = global.jQuery = require('jquery');
require('bootstrap');
require('select2');
require('./select2entity');
require('./utils');
window.xlsx = require('xlsx');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

$.fn.select2.defaults.set("theme", "bootstrap-5");
$.fn.select2.defaults.set("width", "100%");

const navigation = require('./modules/navigation/navigation');
navigation.init();
