$.fn.select2entity = function (options) {
    this.each(function () {
        let request;

        // Keep a reference to the element so we can keep the cache local to this instance and so we can
        // fetch config settings since select2 doesn't expose its options to the transport method.
        let $s2 = $(this),
            scroll = $s2.data('scroll'),
            prefix = Date.now(),
            cache = [];

        let reqParams = $s2.data('req_params');
        if (reqParams) {
            $.each(reqParams, function (key, value) {
                $('*[name="' + value + '"]').on('change', function () {
                    $s2.val(null);
                    $s2.trigger('change');
                });
            });
        }

        // Deep-merge the options
        $s2.select2($.extend(true, {
            allowClear: $s2.data('allow-clear'),
            placeholder: $s2.data('placeholder'),
            // Tags support
            tags: $s2.data('tags'),
            createTag: function (data) {
                if ($s2.data('tags') && data.term.length > 0) {
                    let text = data.term + $s2.data('tags-text');
                    return {id: $s2.data('new-tag-prefix') + data.term, text: text};
                }
            },
            ajax: {
                url: $s2.data('ajax--url') || null,
                delay: $s2.data('ajax--delay'),
                transport: function (params, success, failure) {
                    // is caching enabled?
                    if ($s2.data('ajax--cache')) {
                        // try to make the key unique to make it less likely for a page+q to match a real query
                        let key = prefix + ' page:' + (params.data.page || 1) + ' ' + params.data.q,
                            cacheTimeout = $s2.data('ajax--cacheTimeout');
                        // no cache entry for 'term' or the cache has timed out?
                        if (typeof cache[key] == 'undefined' || (cacheTimeout && Date.now() >= cache[key].time)) {
                            $.ajax(params).fail(failure).done(function (data) {
                                cache[key] = {
                                    data: data,
                                    time: cacheTimeout ? Date.now() + cacheTimeout : null
                                };
                                success(data);
                            });
                        } else {
                            // return cached data with no ajax request
                            success(cache[key].data);
                        }
                    } else {
                        // no caching enabled. just do the ajax request
                        if (request) {
                            request.abort();
                        }
                        request = $.ajax(params).fail(failure).done(success).always(function () {
                            request = undefined;
                        });
                        return request;
                    }
                },
                data: function (params) {
                    let ret = {
                        'q': params.term,
                        'field_name': $s2.data('name'),
                        'class_type': $s2.data('classtype')
                    };

                    let reqParams = $s2.data('req_params');
                    if (reqParams) {
                        $.each(reqParams, function (key, value) {
                            let fieldSelector = '[name^="' + value + '"]';
                            let field = $(fieldSelector);
                            if (field.length > 1) {
                                if (field.is('[type="checkbox"]')) {
                                    ret[key] = [];
                                    field.each(function () {
                                        if ($(this).is(':checked')) {
                                            ret[key].push($(this).val());
                                        }
                                    });
                                } else if (field.is('[type="radio"]')) {
                                    ret[key] = $(fieldSelector + ':checked').val();
                                } else if (field.is('[type="text"]') || field.is('[type="email"]')) {
                                    ret[key] = [];
                                    field.each(function () {
                                        ret[key].push($(this).val());
                                    });
                                } else {
                                    ret[key] = field.val();
                                }
                            } else {
                                if (field.is('[type="checkbox"]') || field.is('[type="radio"]')) {
                                    ret[key] = field.is(':checked');
                                } else {
                                    ret[key] = field.val();
                                }
                            }
                        });
                    }

                    // only send the 'page' parameter if scrolling is enabled
                    if (scroll) {
                        ret['page'] = params.page || 1;
                    }

                    return ret;
                },
                processResults: function (data, params) {
                    let results, more = false, response = {};
                    params.page = params.page || 1;

                    if ($.isArray(data)) {
                        results = data;
                    } else if (typeof data == 'object') {
                        // assume remote result was proper object
                        results = data.results;
                        more = data.more;
                    } else {
                        // failsafe
                        results = [];
                    }

                    if (scroll) {
                        response.pagination = {more: more};
                    }
                    response.results = results;

                    return response;
                }
            }
        }, options || {}))
            .on('select2:selecting', function (e) {
                const data = e.params.args.data;
                if (data['id'].startsWith($s2.data('new-tag-prefix')) && $s2.data('persist-instant')) {
                    data['text'] = data['text'].replace($s2.data('tags-text'), '');
                    $.ajax({
                        method: 'POST',
                        url: $s2.data('ajax--url'),
                        data: data
                    }).done(function (newId) {
                        const option = new Option(data['text'], newId, true, true);
                        $(e.target).empty().append(option).trigger('change');
                    });
                }
            })
            .on('select2:close', function (e) {
                const render = $(e.target).parent().find('ul.select2-selection__rendered');
                render.html(render.find('li.select2-selection__choice').filter(function () {
                    return $(this).attr('title') !== '';
                }));
                $(e.target.selectedOptions).replaceWith(($(e.target.selectedOptions).filter(function () {
                    return this.firstChild !== null;
                })));
                $(e.target).trigger('change');
            })
        ;
    });
    return this;
};

$(function () {
    $('.select2entity[data-autostart="true"]').select2entity();
    $('select').on('select2:unselecting', function (ev) {
        if (ev.params.args.originalEvent) {
            ev.params.args.originalEvent.stopPropagation();
        } else {
            $("select").each(function () {
                if ($(this).hasClass("select2-hidden-accessible") && $(this).data('select2').isOpen()) {
                    $(this).select2('close');
                }
            });
            $(this).one('select2:opening', function (ev) {
                ev.preventDefault();
            });
        }
    });
});
