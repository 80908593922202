$(function () {
    $("textarea").each(function (index, element) {
        function resizeTextarea(e) {
            $(e).height('1px');
            let height = (e.scrollHeight) + 'px';
            $(e).height(height);
        }

        resizeTextarea(element);
        element.onchange = element.oninput = function () {
            resizeTextarea(element);
        };
    });

    $(".back-button").click(function (e) {
        e.preventDefault();
        history.back();
    });

    setTimeout(function () {
        $('#alert-message').fadeOut('fast')
    }, 5000);
});