const $ = require('jquery');

module.exports.init = function () {
    const sub = $('#navigation').find('> ul > li');
    sub.click(function () {
        $(this).find('> ul').slideToggle();
        $(this).toggleClass('opened');
    });
    sub.each(function () {
        if ($(this).hasClass('current_ancestor')) {
            $(this).addClass('opened');
            $(this).find('> ul').slideDown();
        }
    });
    $('#navigation-switch').click(function () {
        $('body').toggleClass('smallNav');
    });
};